import React,{Suspense,lazy} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const App = lazy(() => import('./App'));
const Demo = lazy(() => import('../src/components/Demo'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Suspense fallback><App/></Suspense>,
  },
  {
    path: "book-a-meeting",
    element: <Suspense fallback><Demo/></Suspense>,
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
